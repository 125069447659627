export const careCenterTypes: { [key: string]: string } = {
  A01: "노인요양시설",
  A02: "노인전문요양시설",
  A03: "노인요양시설(개정법)",
  A04: "노인요양공동생활가정",
  A05: "노인요양시설(단기보호 전환)",
  AAA: "입소시설",
  B01: "재가노인복지시설 방문요양",
  B02: "재가노인복지시설 방문목욕",
  B03: "재가노인복지시설 주야간보호",
  B04: "재가노인복지시설 단기보호",
  B05: "재가노인복지시설 방문간호",
  C01: "재가장기요양기관 방문요양",
  C02: "재가장기요양기관 방문목욕",
  C03: "재가장기요양기관 주야간보호",
  C04: "재가장기요양기관 단기보호",
  C05: "재가장기요양기관 방문간호",
  C06: "재가장기요양기관 복지용구",
  Z01: "기타",
  G31: "치매전담실 가형 1실",
  G32: "치매전담실 가형 2실",
  G33: "치매전담실 가형 3실",
  G34: "치매전담실 가형 4실",
  G35: "치매전담실 가형 5실",
  G36: "치매전담실 가형 6실",
  G37: "치매전담실 가형 7실",
  G38: "치매전담실 가형 8실",
  G39: "치매전담실 가형 9실",
  G40: "치매전담실 가형 10실",
  G41: "치매전담실 가형 11실",
  G42: "치매전담실 가형 12실",
  G43: "치매전담실 가형 13실",
  G44: "치매전담실 가형 14실",
  G45: "치매전담실 가형 15실",
  G46: "치매전담실 가형 16실",
  G47: "치매전담실 가형 17실",
  G48: "치매전담실 가형 18실",
  G49: "치매전담실 가형 19실",
  G50: "치매전담실 가형 20실",
  G51: "치매전담실 가형 21실",
  G52: "치매전담실 가형 22실",
  G53: "치매전담실 가형 23실",
  G54: "치매전담실 가형 24실",
  G55: "치매전담실 가형 25실",
  G56: "치매전담실 가형 26실",
  G57: "치매전담실 가형 27실",
  G58: "치매전담실 가형 28실",
  G59: "치매전담실 가형 29실",
  G60: "치매전담실 가형 30실",
  G61: "치매전담실 가형 31실",
  G62: "치매전담실 가형 32실",
  G63: "치매전담실 가형 33실",
  G64: "치매전담실 가형 34실",
  G65: "치매전담실 가형 35실",
  G66: "치매전담실 가형 36실",
  G67: "치매전담실 가형 37실",
  G68: "치매전담실 가형 38실",
  G69: "치매전담실 가형 39실",
  G70: "치매전담실 가형 40실",
  G71: "치매전담실 가형 41실",
  G72: "치매전담실 가형 42실",
  G73: "치매전담실 가형 43실",
  G74: "치매전담실 가형 44실",
  G75: "치매전담실 가형 45실",
  G76: "치매전담실 가형 46실",
  G77: "치매전담실 가형 47실",
  G78: "치매전담실 가형 48실",
  G79: "치매전담실 가형 49실",
  G80: "치매전담실 가형 50실",
  G81: "치매전담실 가형 51실",
  G82: "치매전담실 가형 52실",
  G83: "치매전담실 가형 53실",
  G84: "치매전담실 가형 54실",
  G85: "치매전담실 가형 55실",
  G86: "치매전담실 가형 56실",
  G87: "치매전담실 가형 57실",
  G88: "치매전담실 가형 58실",
  G89: "치매전담실 가형 59실",
  G90: "치매전담실 가형 60실",
  G91: "치매전담실 가형 61실",
  G92: "치매전담실 가형 62실",
  G93: "치매전담실 가형 63실",
  G94: "치매전담실 가형 64실",
  G95: "치매전담실 가형 65실",
  G96: "치매전담실 가형 66실",
  G97: "치매전담실 가형 67실",
  G98: "치매전담실 가형 68실",
  G99: "치매전담실 가형 69실",
  M31: "치매전담실 나형 1실",
  M32: "치매전담실 나형 2실",
  M33: "치매전담실 나형 3실",
  M34: "치매전담실 나형 4실",
  M35: "치매전담실 나형 5실",
  M36: "치매전담실 나형 6실",
  M37: "치매전담실 나형 7실",
  M38: "치매전담실 나형 8실",
  M39: "치매전담실 나형 9실",
  M40: "치매전담실 나형 10실",
  M41: "치매전담실 나형 11실",
  M42: "치매전담실 나형 12실",
  M43: "치매전담실 나형 13실",
  M44: "치매전담실 나형 14실",
  M45: "치매전담실 나형 15실",
  M46: "치매전담실 나형 16실",
  M47: "치매전담실 나형 17실",
  M48: "치매전담실 나형 18실",
  M49: "치매전담실 나형 19실",
  M50: "치매전담실 나형 20실",
  M51: "치매전담실 나형 21실",
  M52: "치매전담실 나형 22실",
  M53: "치매전담실 나형 23실",
  M54: "치매전담실 나형 24실",
  M55: "치매전담실 나형 25실",
  M56: "치매전담실 나형 26실",
  M57: "치매전담실 나형 27실",
  M58: "치매전담실 나형 28실",
  M59: "치매전담실 나형 29실",
  M60: "치매전담실 나형 30실",
  M61: "치매전담실 나형 31실",
  M62: "치매전담실 나형 32실",
  M63: "치매전담실 나형 33실",
  M64: "치매전담실 나형 34실",
  M65: "치매전담실 나형 35실",
  M66: "치매전담실 나형 36실",
  M67: "치매전담실 나형 37실",
  M68: "치매전담실 나형 38실",
  M69: "치매전담실 나형 39실",
  M70: "치매전담실 나형 40실",
  M71: "치매전담실 나형 41실",
  M72: "치매전담실 나형 42실",
  M73: "치매전담실 나형 43실",
  M74: "치매전담실 나형 44실",
  M75: "치매전담실 나형 45실",
  M76: "치매전담실 나형 46실",
  M77: "치매전담실 나형 47실",
  M78: "치매전담실 나형 48실",
  M79: "치매전담실 나형 49실",
  M80: "치매전담실 나형 50실",
  M81: "치매전담실 나형 51실",
  M82: "치매전담실 나형 52실",
  M83: "치매전담실 나형 53실",
  M84: "치매전담실 나형 54실",
  M85: "치매전담실 나형 55실",
  M86: "치매전담실 나형 56실",
  M87: "치매전담실 나형 57실",
  M88: "치매전담실 나형 58실",
  M89: "치매전담실 나형 59실",
  M90: "치매전담실 나형 60실",
  M91: "치매전담실 나형 61실",
  M92: "치매전담실 나형 62실",
  M93: "치매전담실 나형 63실",
  M94: "치매전담실 나형 64실",
  M95: "치매전담실 나형 65실",
  M96: "치매전담실 나형 66실",
  M97: "치매전담실 나형 67실",
  M98: "치매전담실 나형 68실",
  M99: "치매전담실 나형 69실",
  H31: "주야간보호 내 치매전담 1실",
  H32: "주야간보호 내 치매전담 2실",
  H33: "주야간보호 내 치매전담 3실",
  H34: "주야간보호 내 치매전담 4실",
  H35: "주야간보호 내 치매전담 5실",
  H36: "주야간보호 내 치매전담 6실",
  H37: "주야간보호 내 치매전담 7실",
  H38: "주야간보호 내 치매전담 8실",
  H39: "주야간보호 내 치매전담 9실",
  H40: "주야간보호 내 치매전담 10실",
  H41: "주야간보호 내 치매전담 11실",
  H42: "주야간보호 내 치매전담 12실",
  H43: "주야간보호 내 치매전담 13실",
  H44: "주야간보호 내 치매전담 14실",
  H45: "주야간보호 내 치매전담 15실",
  H46: "주야간보호 내 치매전담 16실",
  H47: "주야간보호 내 치매전담 17실",
  H48: "주야간보호 내 치매전담 18실",
  H49: "주야간보호 내 치매전담 19실",
  H50: "주야간보호 내 치매전담 20실",
  H51: "주야간보호 내 치매전담 21실",
  H52: "주야간보호 내 치매전담 22실",
  H53: "주야간보호 내 치매전담 23실",
  H54: "주야간보호 내 치매전담 24실",
  H55: "주야간보호 내 치매전담 25실",
  H56: "주야간보호 내 치매전담 26실",
  H57: "주야간보호 내 치매전담 27실",
  H58: "주야간보호 내 치매전담 28실",
  H59: "주야간보호 내 치매전담 29실",
  H60: "주야간보호 내 치매전담 30실",
  H61: "주야간보호 내 치매전담 31실",
  H62: "주야간보호 내 치매전담 32실",
  H63: "주야간보호 내 치매전담 33실",
  H64: "주야간보호 내 치매전담 34실",
  H65: "주야간보호 내 치매전담 35실",
  H66: "주야간보호 내 치매전담 36실",
  H67: "주야간보호 내 치매전담 37실",
  H68: "주야간보호 내 치매전담 38실",
  H69: "주야간보호 내 치매전담 39실",
  H70: "주야간보호 내 치매전담 40실",
  H71: "주야간보호 내 치매전담 41실",
  H72: "주야간보호 내 치매전담 42실",
  H73: "주야간보호 내 치매전담 43실",
  H74: "주야간보호 내 치매전담 44실",
  H75: "주야간보호 내 치매전담 45실",
  H76: "주야간보호 내 치매전담 46실",
  H77: "주야간보호 내 치매전담 47실",
  H78: "주야간보호 내 치매전담 48실",
  H79: "주야간보호 내 치매전담 49실",
  H80: "주야간보호 내 치매전담 50실",
  H81: "주야간보호 내 치매전담 51실",
  H82: "주야간보호 내 치매전담 52실",
  H83: "주야간보호 내 치매전담 53실",
  H84: "주야간보호 내 치매전담 54실",
  H85: "주야간보호 내 치매전담 55실",
  H86: "주야간보호 내 치매전담 56실",
  H87: "주야간보호 내 치매전담 57실",
  H88: "주야간보호 내 치매전담 58실",
  H89: "주야간보호 내 치매전담 59실",
  H90: "주야간보호 내 치매전담 60실",
  H91: "주야간보호 내 치매전담 61실",
  H92: "주야간보호 내 치매전담 62실",
  H93: "주야간보호 내 치매전담 63실",
  H94: "주야간보호 내 치매전담 64실",
  H95: "주야간보호 내 치매전담 65실",
  H96: "주야간보호 내 치매전담 66실",
  H97: "주야간보호 내 치매전담 67실",
  H98: "주야간보호 내 치매전담 68실",
  H99: "주야간보호 내 치매전담 69실",
  I31: "주야간보호 내 치매전담 1실",
  I32: "주야간보호 내 치매전담 2실",
  I33: "주야간보호 내 치매전담 3실",
  I34: "주야간보호 내 치매전담 4실",
  I35: "주야간보호 내 치매전담 5실",
  I36: "주야간보호 내 치매전담 6실",
  I37: "주야간보호 내 치매전담 7실",
  I38: "주야간보호 내 치매전담 8실",
  I39: "주야간보호 내 치매전담 9실",
  I40: "주야간보호 내 치매전담 10실",
  I41: "주야간보호 내 치매전담 11실",
  I42: "주야간보호 내 치매전담 12실",
  I43: "주야간보호 내 치매전담 13실",
  I44: "주야간보호 내 치매전담 14실",
  I45: "주야간보호 내 치매전담 15실",
  I46: "주야간보호 내 치매전담 16실",
  I47: "주야간보호 내 치매전담 17실",
  I48: "주야간보호 내 치매전담 18실",
  I49: "주야간보호 내 치매전담 19실",
  I50: "주야간보호 내 치매전담 20실",
  I51: "주야간보호 내 치매전담 21실",
  I52: "주야간보호 내 치매전담 22실",
  I53: "주야간보호 내 치매전담 23실",
  I54: "주야간보호 내 치매전담 24실",
  I55: "주야간보호 내 치매전담 25실",
  I56: "주야간보호 내 치매전담 26실",
  I57: "주야간보호 내 치매전담 27실",
  I58: "주야간보호 내 치매전담 28실",
  I59: "주야간보호 내 치매전담 29실",
  I60: "주야간보호 내 치매전담 30실",
  I61: "주야간보호 내 치매전담 31실",
  I62: "주야간보호 내 치매전담 32실",
  I63: "주야간보호 내 치매전담 33실",
  I64: "주야간보호 내 치매전담 34실",
  I65: "주야간보호 내 치매전담 35실",
  I66: "주야간보호 내 치매전담 36실",
  I67: "주야간보호 내 치매전담 37실",
  I68: "주야간보호 내 치매전담 38실",
  I69: "주야간보호 내 치매전담 39실",
  I70: "주야간보호 내 치매전담 40실",
  I71: "주야간보호 내 치매전담 41실",
  I72: "주야간보호 내 치매전담 42실",
  I73: "주야간보호 내 치매전담 43실",
  I74: "주야간보호 내 치매전담 44실",
  I75: "주야간보호 내 치매전담 45실",
  I76: "주야간보호 내 치매전담 46실",
  I77: "주야간보호 내 치매전담 47실",
  I78: "주야간보호 내 치매전담 48실",
  I79: "주야간보호 내 치매전담 49실",
  I80: "주야간보호 내 치매전담 50실",
  I81: "주야간보호 내 치매전담 51실",
  I82: "주야간보호 내 치매전담 52실",
  I83: "주야간보호 내 치매전담 53실",
  I84: "주야간보호 내 치매전담 54실",
  I85: "주야간보호 내 치매전담 55실",
  I86: "주야간보호 내 치매전담 56실",
  I87: "주야간보호 내 치매전담 57실",
  I88: "주야간보호 내 치매전담 58실",
  I89: "주야간보호 내 치매전담 59실",
  I90: "주야간보호 내 치매전담 60실",
  I91: "주야간보호 내 치매전담 61실",
  I92: "주야간보호 내 치매전담 62실",
  I93: "주야간보호 내 치매전담 63실",
  I94: "주야간보호 내 치매전담 64실",
  I95: "주야간보호 내 치매전담 65실",
  I96: "주야간보호 내 치매전담 66실",
  I97: "주야간보호 내 치매전담 67실",
  I98: "주야간보호 내 치매전담 68실",
  I99: "주야간보호 내 치매전담 69실",
  S41: "치매전담형 노인요양공동생활가정",
};
