import { careCenterTypes } from "./careCenterTypes";

type Props = {
  adminNm: string;
  adminPttnCdList: string[];
  address: string;
  phoneNumber: string;
};

const Card: React.FC<Props> = (props: Props) => {
  const handleCall = () => {
    window.location.href = `tel:${props.phoneNumber}`;
  };

  const handleMap = () => {
    const mapUrl = `https://map.naver.com/v5/search/${props.address}`;

    window.open(mapUrl, "_blank");
  };

  return (
    <div
      style={{
        border: "1px solid grey",
        padding: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div style={{ width: "30%" }}></div> */}
        <div>
          <h3>{props.adminNm}</h3>
          <p style={{ margin: 0 }}>
            {props.adminPttnCdList
              .map((code: string) => careCenterTypes[code] || code)
              .join(", ")}
          </p>
          <p style={{ margin: 0 }}>{props.address}</p>
          <p style={{ margin: 0 }}>{props.phoneNumber}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 1rem",
        }}
      >
        <button style={{ marginRight: 10 }} onClick={handleCall}>
          전화걸기
        </button>
        <button onClick={handleMap}>지도보기</button>
      </div>
    </div>
  );
};

export default Card;
