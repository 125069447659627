type Props = {
  mobileWidth: number;
};

export function Footer(props: Props) {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        height: 56,
        width: props.mobileWidth,
        border: "1px solid blue",
        background: "white",
      }}
    ></footer>
  );
}
