type Props = {
  mobileWidth: number;
};

export function Header(props: Props) {
  return (
    <header
      style={{
        position: "fixed",
        top: 0,
        height: 56,
        width: props.mobileWidth,
        border: "1px solid red",
        background: "white",
      }}
    >
      <p style={{ margin: 0 }}>케어네비</p>
    </header>
  );
}
