// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAvyfnuBnDe4pTHzbH59WhpHiaE5hC4YFE",
  authDomain: "carenavi-e30ec.firebaseapp.com",
  projectId: "carenavi-e30ec",
  storageBucket: "carenavi-e30ec.firebasestorage.app",
  messagingSenderId: "1092939558256",
  appId: "1:1092939558256:web:c2fa8cdeca30a1efee33b5",
  measurementId: "G-PQS7YE43MV",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
