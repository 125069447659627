import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebaseConfig";
import { Header } from "./Header";
import { Footer } from "./Footer";
import Card from "./Card";

const mobileWidth = 430;

type LtcInsttGroupedItem = {
  adminNm: string;
  adminPttnCdList: string[];
  longTermAdminSym: string;
  longTermPeribRgtDt: string;
  siDoCd: string;
  siGunGuCd: string;
  stpRptDt: string;
};

interface LtcInstt {
  adminNm: string;
  adminPttnCdList: string[];
  longTermAdminSym: string;
  longTermPeribRgtDt: string;
  stpRptDt: string;
  phoneNumber: string;
  detailAddr: string;
  bdongCd: string;
  hdongCd: string;
  roadNmCd: string;
  roadNmBuildingNumber: string;
  floor: string;
}

function App() {
  const [careCenters, setCareCenters] = useState<LtcInstt[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCareCenters = useCallback(async () => {
    setLoading(true);
    try {
      const q = query(collection(db, "ltcInstts"), orderBy("adminNm"));

      const querySnapshot = await getDocs(q);
      const newCareCenters: LtcInstt[] = [];
      querySnapshot.forEach((doc) => {
        newCareCenters.push(doc.data() as LtcInstt);
      });

      setCareCenters(newCareCenters);
    } catch (error) {
      console.error("Error fetching care centers:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCareCenters();
  }, [fetchCareCenters]);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {/* mobile format */}
      <div
        style={{
          height: "100%",
          width: mobileWidth,
          border: "1px solid black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header mobileWidth={mobileWidth} />

        {/* main */}
        <main style={{ width: "100%", paddingTop: 56 }}>
          <InfiniteScroll
            dataLength={careCenters.length}
            next={fetchCareCenters}
            hasMore={false}
            loader={<h4>Loading...</h4>}
            endMessage={<p>No more care centers to show</p>}
          >
            {careCenters.map((center, index) => (
              <Card
                adminNm={center.adminNm}
                adminPttnCdList={center.adminPttnCdList}
                address={center.bdongCd}
                phoneNumber={center.phoneNumber}
              />
            ))}
          </InfiniteScroll>
        </main>

        <Footer mobileWidth={mobileWidth} />
      </div>
    </div>
  );
}

export default App;
